import { Helmet } from "react-helmet";
import SideDashboard from "../dashboard/dashboard";
import PhotoGuide from "../content/photo/photo";
import { NavLink } from "react-router-dom";
import allImages from "../../utils/images";
import TestNav from "../testNav/testNav";
import useFsService from "../../services/fsService";
import { useEffect, useState } from "react";
import ErrorMessage from "../error/error";
import Spinner from "../spinner/spinner";

const PhotoPage = () => {
  const { startNewTest, error, loading } = useFsService();
  const [hasError, setHasError] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initTest = async () => {
      try {
        const res = await startNewTest();

        if (res) {
          setIsInitialized(true);
        } else {
          setHasError(true);
        }
      } catch (e) {
        setHasError(true);
      }
    };

    initTest();
  }, []);

  const errorMessage = hasError || error ? <ErrorMessage /> : null;
  const spinner = loading ? <Spinner /> : null;
  const content = isInitialized && !loading && !hasError ? <View /> : null;

  return (
    <>
      <Helmet>
        <meta name="description" content="Анализ фото" />
        <title>Photo analysis</title>
      </Helmet>
      <main>
        {spinner}
        {errorMessage}
        {content}
      </main>
    </>
  );
};

const View = () => {
  return (
    <>
      <aside className="dashboard">
        <SideDashboard type={"test"} />
      </aside>
      <NavLink className="mob__logo" to="/">
        <img src={allImages.DashLogoWebp} alt="Logo" />
      </NavLink>
      <aside className="mob__nav">
        <TestNav />
      </aside>
      <PhotoGuide />
    </>
  );
};

export default PhotoPage;
