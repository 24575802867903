import { createContext, useState, useContext, useEffect } from "react";

const ProgressContext = createContext();

export const useProgress = () => useContext(ProgressContext);

export const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState(() => {
    const savedProgress = localStorage.getItem("progress");
    return savedProgress
      ? JSON.parse(savedProgress)
      : {
          photoUploaded: false,
          testCompleted: false,
          resultsViewed: false,
        };
  });

  const updateProgress = (newProgress) => {
    setProgress((prev) => {
      const updatedProgress = {
        ...prev,
        ...newProgress,
      };
      localStorage.setItem("progress", JSON.stringify(updatedProgress));
      return updatedProgress;
    });
  };

  const resetProgress = () => {
    const initialProgress = {
      photoUploaded: false,
      testCompleted: false,
      resultsViewed: false,
    };
    setProgress(initialProgress);
    localStorage.removeItem("progress");
  };

  useEffect(() => {
    const savedProgress = localStorage.getItem("progress");
    if (savedProgress) {
      setProgress(JSON.parse(savedProgress));
    }
  }, []);

  return (
    <ProgressContext.Provider value={{ progress, updateProgress, resetProgress }}>
      {children}
    </ProgressContext.Provider>
  );
};
