import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../providers/authProvider";
import { useLanguageRegion } from "../providers/LanguageRegionContext";
import allImages from "../../utils/images";
import SelectionModal from "../modal/locationModal";
import Modal, { closeModal, openModal } from "../modal/modal";
import QuestionModal from "../modal/QuestionModal";

const MobMenu = () => {
  const { lang, region, updateLang, updateRegion } = useLanguageRegion();
  const [openModalName, setOpenModalName] = useState(null);
  const { isLoggedIn } = useAuthContext();

  // Обработчик клика для закрытия модалки и выполнения действия
  const handleLinkClick = () => {
    closeModal(setOpenModalName);
  };

  // Обработчик для открытия модалки
  const handleOpenModal = (modalName) => {
    closeModal(setOpenModalName); // Закрыть любую открытую модалку перед открытием новой
    openModal(setOpenModalName, modalName);
  };

  return (
    <>
      <nav className="mob__menu">
        <ul>
          <li>
            <NavLink to="/" onClick={handleLinkClick}>
              <allImages.MobIndex />
              <span>Главная</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/photo" onClick={handleLinkClick}>
              <allImages.MobTest />
              <span>Анализ</span>
            </NavLink>
          </li>
          <li>
            {isLoggedIn ? (
              <NavLink to="/profile" onClick={handleLinkClick}>
                <allImages.MobProfile />
                <span>Профиль</span>
              </NavLink>
            ) : (
              <NavLink to="/login" onClick={handleLinkClick}>
                <allImages.MobProfile />
                <span>Войти</span>
              </NavLink>
            )}
          </li>
          <li>
            <button
              type="button"
              onClick={() => handleOpenModal("mobMenu")}
            >
              <allImages.MobMenu />
              <span>Меню</span>
            </button>
          </li>
        </ul>
      </nav>

      {openModalName === "mobMenu" && (
        <Modal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Меню"
        >
          <div className="mob__buttons">
            {isLoggedIn ? (
              <NavLink
                to="/profile"
                className="btn btn--blue"
                onClick={handleLinkClick}
              >
                Профиль
              </NavLink>
            ) : (
              <NavLink
                to="/signup"
                className="btn btn--blue"
                onClick={handleLinkClick}
              >
                Регистрация
              </NavLink>
            )}
            {isLoggedIn ? (
              <NavLink
                to="/signout"
                className="btn btn--transblue"
                onClick={handleLinkClick}
              >
                Выйти
              </NavLink>
            ) : (
              <NavLink
                to="/login"
                className="btn btn--transblue"
                onClick={handleLinkClick}
              >
                Войти
              </NavLink>
            )}
          </div>
          <ul className="mob__links">
            <li>
              <button
                type="button"
                onClick={() => handleOpenModal("language")}
              >
                <span>Выберите язык: {lang.nameLang}</span>
                <allImages.NextArrow />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => handleOpenModal("region")}
              >
                <span>Выберите регион: {region.nameReg}</span>
                <allImages.NextArrow />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => handleOpenModal("question")}
              >
                <span>Задать вопрос</span>
                <allImages.NextArrow />
              </button>
            </li>
            <li>
              <NavLink to="/improve" onClick={handleLinkClick}>
                <span>Помогите нам стать лучше</span>
                <allImages.NextArrow />
              </NavLink>
            </li>
            <li>
              <NavLink to="/faq" onClick={handleLinkClick}>
                <span>Вопросы / Ответы</span>
                <allImages.NextArrow />
              </NavLink>
            </li>
            <li>
              <NavLink to="/rules" onClick={handleLinkClick}>
                <span>Условия использования сервиса</span>
                <allImages.NextArrow />
              </NavLink>
            </li>
            <li>
              <NavLink to="/privacy" onClick={handleLinkClick}>
                <span>Политика конфиденциальности</span>
                <allImages.NextArrow />
              </NavLink>
            </li>
          </ul>
        </Modal>
      )}

      {openModalName === "language" && (
        <SelectionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Выберите язык"
          type="language"
          selectedOption={lang}
          setSelectedOption={updateLang}
        />
      )}

      {openModalName === "region" && (
        <SelectionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Выберите регион"
          type="region"
          selectedOption={region}
          setSelectedOption={updateRegion}
        />
      )}
      {openModalName === "question" && (
        <QuestionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
        />
      )}
    </>
  );
};

export default MobMenu;
