import { Helmet } from "react-helmet";
import allImages from "../../utils/images";
import SideDashboard from "../dashboard/dashboard";
import { NavLink } from "react-router-dom";
import TestNav from "../testNav/testNav";
import TestSurvey from "../content/test/test";

const TestPage = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="Пройдите анкетирование" />
        <title>Test analysis</title>
      </Helmet>
      <main>
        <aside className="dashboard">
          <SideDashboard type={"test"} />
        </aside>
        <NavLink className="mob__logo" to="/">
          <img src={allImages.DashLogoWebp} alt="Logo" />
        </NavLink>
        <aside className="mob__nav">
          <TestNav />
        </aside>
        <TestSurvey />
      </main>
    </>
  );
};

export default TestPage;
