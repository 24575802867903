import { Helmet } from "react-helmet";
import SideDashboard from "../dashboard/dashboard";
import { NavLink } from "react-router-dom";
import allImages from "../../utils/images";
import TestNav from "../testNav/testNav";
import RecsContent from "../content/recs/recs";

const RecsPage = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Рекомендации по уходу за кожей лица"
        />
        <title>Face skin recommendations</title>
      </Helmet>
      <main>
        <aside className="dashboard">
          <SideDashboard type={"test"} />
        </aside>
        <NavLink className="mob__logo" to="/">
          <img src={allImages.DashLogoWebp} alt="Logo" />
        </NavLink>
        <aside className="mob__nav">
          <TestNav />
        </aside>
        <RecsContent />
      </main>
    </>
  );
};

export default RecsPage;
