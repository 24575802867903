import { useEffect, useState } from "react";
import useFsService from "../../../../services/fsService";

const useRecsBasket = () => {
  const { getBasket, putInBasket, clearError } = useFsService();
  const [cart, setCart] = useState({});
  const [basketTotal, setBasketTotal] = useState(0);
  const [loadingProducts, setLoadingProducts] = useState({});
  const [errorProducts, setErrorProducts] = useState({});

  useEffect(() => {
    const fetchBasket = async () => {
      clearError();
      try {
        const basketResponse = await getBasket();
        if (basketResponse.data.result) {
          const basketData = basketResponse.data;
          const newCart = {};
          const products = basketResponse.data.data.products;
          if (products.length > 0) {
            products.forEach((product) => {
              newCart[product.code] = product.count;
            });
          }

          setCart(newCart);
          setBasketTotal(basketData.data.total_items);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchBasket();
  }, []);

  const handleAddToCart = async (productCode) => {
    const newCount = (cart[productCode] || 0) + 1;

    setLoadingProducts((prev) => ({
      ...prev,
      [productCode]: true,
    }));

    setErrorProducts((prev) => ({
      ...prev,
      [productCode]: false,
    }));

    setCart((prevCart) => ({
      ...prevCart,
      [productCode]: newCount,
    }));

    setBasketTotal((prevTotal) => prevTotal + 1);

    try {
      await putInBasket(newCount, productCode);
    } catch (e) {
      setErrorProducts((prev) => ({
        ...prev,
        [productCode]: true,
      }));
    } finally {
      setLoadingProducts((prev) => ({
        ...prev,
        [productCode]: false,
      }));
    }
  };

  const handleRemoveFromCart = async (productCode) => {
    const currentCount = cart[productCode] || 0;
    const newCount = currentCount - 1;

    setLoadingProducts((prev) => ({
      ...prev,
      [productCode]: true,
    }));

    setErrorProducts((prev) => ({
      ...prev,
      [productCode]: false,
    }));

    if (newCount <= 0) {
      setCart((prevCart) => {
        const { [productCode]: _, ...rest } = prevCart;
        return rest;
      });

      setBasketTotal((prevTotal) => prevTotal - 1);

      try {
        await putInBasket(0, productCode);
      } catch (e) {
        setErrorProducts((prev) => ({
          ...prev,
          [productCode]: true,
        }));
      } finally {
        setLoadingProducts((prev) => ({
          ...prev,
          [productCode]: false,
        }));
      }
    } else {
      setCart((prevCart) => ({
        ...prevCart,
        [productCode]: newCount,
      }));

      setBasketTotal((prevTotal) => prevTotal - 1);

      try {
        await putInBasket(newCount, productCode);
      } catch (e) {
        setErrorProducts((prev) => ({
          ...prev,
          [productCode]: true,
        }));
      } finally {
        setLoadingProducts((prev) => ({
          ...prev,
          [productCode]: false,
        }));
      }
    }
  };

  return {
    cart,
    basketTotal,
    handleAddToCart,
    handleRemoveFromCart,
    loadingProducts,
    errorProducts,
  };
};

export default useRecsBasket;
