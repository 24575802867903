import { Formik, Form } from "formik";
import allImages from "../../../utils/images";
import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import useFsService from "../../../services/fsService";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../error/error";
import SuccessScreen from "../../successScreen/successScreen";
import { useProgress } from "../../providers/ProgressContext";

const PhotoGuide = () => {
  const { error, postPhoto } = useFsService();
  const [step, setStep] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const [progress, setProgress] = useState(0);
  const [fileError, setFileError] = useState("");
  const [submissionError, setSubmissionError] = useState(false);

  const { updateProgress, resetProgress } = useProgress();

  const validFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
  const MAX_IMAGE_DIMENSION = 5000; // 5000 pixels

  const containerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    resetProgress();
  }, []);

  useEffect(() => {
    animateStep();
  }, [step]);

  const animateStep = () => {
    if (!containerRef.current) return;

    const animations = {
      1: {
        from: { opacity: 0, y: 50 },
        to: { opacity: 1, y: 0, duration: 1, ease: "power3.out" },
      },
      2: {
        from: { scale: 0.8, opacity: 0 },
        to: { scale: 1, opacity: 1, duration: 1, ease: "elastic.out(1, 0.5)" },
      },
      3: {
        from: { x: -100, opacity: 0 },
        to: { x: 0, opacity: 1, duration: 1.5, ease: "bounce.out" },
      },
    };

    const { from, to } = animations[step] || {};
    if (from && to) {
      gsap.fromTo(containerRef.current, from, to);
    }
  };

  const validateFile = (file) => {
    if (!validFileTypes.includes(file.type)) {
      return "Недопустимый формат файла. Допускаются только jpeg, jpg, png, webp.";
    }
    if (file.size > MAX_FILE_SIZE) {
      return "Файл слишком большой. Размер файла не должен превышать 10 МБ.";
    }
    return "";
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const fileValidationError = validateFile(file);
      if (fileValidationError) {
        setFileError(fileValidationError);
        setFieldValue("file", null);
        setSelectedImage(null);
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = () => {
        const img = new Image();
        img.onload = () => {
          if (img.width < 300 || img.height < 300) {
            setFileError(
              "Изображение слишком маленькое. Минимальный размер - 300x300 пикселей."
            );
            setFieldValue("file", null);
            setSelectedImage(null);
          } else if (
            img.width > MAX_IMAGE_DIMENSION ||
            img.height > MAX_IMAGE_DIMENSION
          ) {
            setFileError(
              "Изображение слишком большое. Максимальный размер - 5000x5000 пикселей."
            );
            setFieldValue("file", null);
            setSelectedImage(null);
          } else {
            setFileError("");
            setFieldValue("file", file);
            setSelectedImage(URL.createObjectURL(file));
            setBase64Image(fileReader.result);
          }
        };
        img.onerror = () => {
          setFileError("Ошибка при загрузке изображения");
          setFieldValue("file", null);
          setSelectedImage(null);
        };
        img.src = fileReader.result;
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    setStep(3);
    setProgress(0);
    setSubmissionError(false);

    const progressPromise = new Promise((resolve) => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 99) {
            clearInterval(timer);
            resolve();
          }
          return prevProgress + 1;
        });
      }, 60);
    });

    try {
      const res = await postPhoto(base64Image);
      console.log(res);
      if (res) {
        await progressPromise;
        setStep(5);
        updateProgress({ photoUploaded: true });
      } else {
        await progressPromise;
        setStep(4);
      }
    } catch (error) {
      setSubmissionError(true);
    }
  };

  if (error) return <ErrorMessage />;
  if (submissionError) return <ErrorMessage />;

  return (
    <section className="photo">
      <div className="container">
        <div className="photo__container">
          <div className="photo__title">
            <span>{step}</span>
            <h1>Анализ фотографии</h1>
          </div>
          {step === 1 && (
            <Step1 setStep={setStep} containerRef={containerRef} />
          )}
          {step === 2 && (
            <Step2
              containerRef={containerRef}
              handleFileChange={handleFileChange}
              validateFile={validateFile}
              selectedImage={selectedImage}
              fileError={fileError}
              handleSubmit={handleSubmit}
            />
          )}
          {step === 3 && (
            <Step3
              containerRef={containerRef}
              progress={progress}
              selectedImage={selectedImage}
            />
          )}
          {step === 4 && <Step4 setStep={setStep} />}
          {step === 5 && (
            <SuccessScreen
              message="Ваше фото успешно загружено и отправлено на анализ!"
              countdown={3}
              redirectPath="/test"
            />
          )}
        </div>
      </div>
    </section>
  );
};

const Step1 = ({ setStep, containerRef }) => (
  <div className="photo__guide" ref={containerRef}>
    <div className="photo__guide-main">
      <div className="photo__guide-img">
        <picture>
          <source
            srcSet={`${allImages.PhotoBgWebp} 1x, ${allImages.PhotoBgWebp2x} 2x`}
            type="image/webp"
          />
          <img
            src={allImages.PhotoBg}
            srcSet={allImages.PhotoBg2x}
            width="293"
            height="318"
            alt="Пример фото"
          />
        </picture>
      </div>
      <div className="photo__guide-text">
        <p>
          Для определения вашего дерматологического профиля, сделайте или
          загрузите фотографию
        </p>
        <h2>Рекомендации к фото:</h2>
        <ul>
          <li>Хорошее освещение</li>
          <li>Отсутствие макияжа, очков и головных уборов</li>
          <li>Не улыбайтесь</li>
          <li>Не наклоняйте голову</li>
        </ul>
        <button className="btn btn--blue" onClick={() => setStep(2)}>
          Сделать фото
        </button>
      </div>
    </div>
    <div className="photo__guide-addit">
      <h2>Мобильная версия</h2>
      <p>Если нет камеры или подходящих фото на компьютере</p>
      <picture>
        <source type="image/webp" srcSet={allImages.PhotoQrWebp} />
        <img
          src={allImages.PhotoQr}
          width="203"
          height="203"
          alt="Qr код FaceScan"
        />
      </picture>
    </div>
  </div>
);

const Step2 = ({
  containerRef,
  handleFileChange,
  validationSchema,
  selectedImage,
  fileError,
  handleSubmit,
}) => (
  <div className="photo__upload" ref={containerRef}>
    <Formik
      initialValues={{ file: null }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ setFieldValue }) => (
        <Form className="photo__upload-form">
          <label htmlFor="file">
            {selectedImage ? (
              <img src={selectedImage} alt="Uploaded" />
            ) : (
              <div className="photo__upload-placeholder">
                <span>Нажмите, чтобы загрузить фото</span>
                <allImages.PhotoIcon />
              </div>
            )}
          </label>
          <input
            id="file"
            name="file"
            type="file"
            style={{ display: "none" }}
            accept="image/jpeg,image/jpg,image/png,image/webp"
            onChange={(event) => handleFileChange(event, setFieldValue)}
          />
          {fileError && <div className="photo__upload-error">{fileError}</div>}
          {!selectedImage && (
            <div className="photo__upload-text">
              <h2>Выберите фото или сделайте новое</h2>
              <p>
                Фото должно быть меньше 10Мб весом, в формате JPG, PNG, WEBP, не
                превышать размеров более 5000 пикселей и быть не меньше 300
                пикселей
              </p>
              <button
                type="button"
                className="btn btn--blue"
                onClick={() => document.getElementById("file").click()}
              >
                Выбрать фото
              </button>
            </div>
          )}
          {selectedImage && !fileError && (
            <div className="photo__upload-text">
              <p>
                Отправьте фото на анализ или сделайте новое, в случае неудачной
                попытки
              </p>
              <button
                type="button"
                className="btn btn--transblue"
                onClick={() => document.getElementById("file").click()}
              >
                Выбрать другое фото
              </button>
              <button
                type="submit"
                className="btn btn--blue"
                disabled={!!fileError}
              >
                Использовать фото
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  </div>
);

const Step3 = ({ containerRef, progress, selectedImage }) => (
  <div className="photo__analysis" ref={containerRef}>
    <div className="photo__analysis-container" style={{ position: "relative" }}>
      <img src={selectedImage} alt="Analyzed" style={{ width: "100%" }} />
      <div
        className="photo__analysis-scanner"
        style={{
          zIndex: 5,
          display: "block",
          height: "44px",
          width: "100%",
          background:
            "linear-gradient(to bottom, transparent 0%, rgba(208, 20, 129, 0.6) 48%, #d01481 50%, rgba(208, 20, 129, 0.6) 52%, transparent 100%)",
          position: "absolute",
          top: `${progress}%`,
          left: 0,
          transition: "top 0.1s linear",
        }}
      />
    </div>
    <div className="photo__analysis-progress">
      <span>Распознаем воспаления...</span>
      <progress value={progress} max="100"></progress>
      <span>{progress}%</span>
    </div>
  </div>
);

const Step4 = ({ setStep }) => {
  return (
    <>
      <div className="photo__upload">
        <div className="photo__upload-placeholder">
          <allImages.PhotoIcon />
        </div>
        <div className="photo__upload-text">
          <h2>Вашу фотографию не удалось распознать!</h2>
          <h3>Попробуйте сделать следующее:</h3>
          <ul>
            <li>Найдите помещение с хорошим освещением</li>
            <li>
              Выберите удобный ракурс фотографии, чтобы снимок получился четким
            </li>
            <li>Поместите ваши ногти в центр кадра</li>
          </ul>
          <button
            type="button"
            className="btn btn--blue"
            onClick={() => setStep(2)}
          >
            <allImages.PhotoCamera />
            Сделать новое
          </button>
        </div>
      </div>
    </>
  );
};

export default PhotoGuide;
