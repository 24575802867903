import ErrorMessage from "../../../error/error";
import Spinner from "../../../spinner/spinner";

const FaceConditions = ({ loading, error, errorText, resultData }) => {
  const spinner = loading ? <Spinner /> : null;
  const errorMessage = error ? (
    <ErrorMessage
      message={errorText || "Не удалось загрузить детализацию показателей"}
    />
  ) : null;

  const content = renderFaceConds(resultData?.face_conds);

  return (
    <>
      {errorMessage}
      {spinner}
      {content}
    </>
  );
};

const renderFaceConds = (faceConds) => {
  if (!faceConds) {
    return <p>Данные о показателях отсутствуют.</p>;
  }

  return (
    <ul className="result__conds-data">
      {Object.entries(faceConds).map(([key, value]) => (
        <li key={key}>
          <span>{key}</span>
          <div className="result__conds-bar">
            <div
              className="result__conds-bar--filled"
              style={{ width: `${value}%` }}
            ></div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FaceConditions;
