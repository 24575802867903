import { Helmet } from "react-helmet";
import SideDashboard from "../dashboard/dashboard";
import TestNav from "../testNav/testNav";
import { NavLink } from "react-router-dom";
import allImages from "../../utils/images";
import { useState } from "react";
import ResultContent from "../content/result/result";

const ResultPage = () => {
  const [resultComponent, setResultComponent] = useState(null);

  return (
    <>
      <Helmet>
        <meta name="description" content="Результаты анализа" />
        <title>Results</title>
      </Helmet>

      <main>
        <aside className="dashboard">
          <SideDashboard type={"test"} />
        </aside>
        <NavLink className="mob__logo" to="/">
          <img src={allImages.DashLogoWebp} alt="Logo" />
        </NavLink>
        <aside className="mob__nav">
          <TestNav />
        </aside>

        <ResultContent setResultComponent={setResultComponent} />

        {resultComponent}
      </main>
    </>
  );
};

export default ResultPage;
