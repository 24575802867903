import React from "react";
import { NavLink } from "react-router-dom";
import "./error.scss";
import gif from "./error.gif";

const ErrorMessage = ({ errorText }) => {
  return (
    <div className="error">
      <img
        src={gif}
        alt="Error"
      />
      <div className="error__content">
        <h2>Произошла ошибка</h2>
        <span>
          {errorText || "Ведутся работы или сервер временно недоступен"}
        </span>
        <p>Попробуйте повторить действие позднее</p>
        <NavLink className="btn btn--blue" to="/">
          На главную
        </NavLink>
      </div>
    </div>
  );
};

export default ErrorMessage;
