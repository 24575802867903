import axios from "axios";
import { useCallback, useState } from "react";
import { useAuthContext } from "../components/providers/authProvider";

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorText, setErrorText] = useState(null);

  const authContext = useAuthContext();

  if (!authContext) {
    throw new Error("useHttp must be used within an AuthProvider");
  }

  const { tempToken, accessToken } = authContext;
  const token = tempToken || accessToken;

  const request = useCallback(
    async (
      url,
      method = "GET",
      body = null,
      headers = {
        "Content-Type": "application/json",
        "J-Authorization": `${token}`,
      }
    ) => {
      setLoading(true);
      setError(null);

      try {
        console.log("Sending Request:", { url, method, headers, body });

        const response = await axios({
          url,
          method,
          headers,
          data: body,
        });

        setErrorText(response.data.error ? response.data.error : null);

        setLoading(false);
        console.log(response)
        return response;
      } catch (e) {
        console.error(e.response)
        setLoading(false);
        setError(e.message);
        setErrorText(null);
        throw e;
      }
    },
    [token]
  );

  const clearError = useCallback(() => setError(null), []);

  return { loading, error, request, clearError, errorText };
};
