import { useEffect, useState, useRef } from "react";
import useFsService from "../../../services/fsService";
import ErrorMessage from "../../error/error";
import Spinner from "../../spinner/spinner";
import Survey from "../../testSurvey/testSurvey";

const TestSurvey = () => {
  const { getTestQuestions } = useFsService();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const isRequestInProgress = useRef(false);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (hasFetchedData.current || isRequestInProgress.current) {
      return;
    }

    isRequestInProgress.current = true;

    const controller = new AbortController();
    const { signal } = controller;

    const getTests = async () => {
      try {
        const res = await getTestQuestions({ signal });
        if (res.data.result) {
          setQuestions(res.data.data.questions);
          hasFetchedData.current = true;
        } else {
          setHasError(true);
        }
      } catch (e) {
        if (!signal.aborted) {
          setHasError(true);
        }
      } finally {
        setIsLoading(false);
        isRequestInProgress.current = false;
      }
    };

    getTests();

    return () => {
      controller.abort();
    };
  }, [getTestQuestions]);


  if (isLoading) return <Spinner />;
  if (hasError) return <ErrorMessage />;

  return (
    <Survey
      questions={questions}
      title={"Анкета"}
      initialAnswers={{}}
      redirectPath="/result"
      successMessage={"Ваши ответы успешно загружены и отправлены на анализ!"}
    />
  );
};

export default TestSurvey;
