import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ErrorMessage from "../../../error/error";
import Spinner from "../../../spinner/spinner";
import Modal, { closeModal, openModal } from "../../../modal/modal";
import ImproveModal from "../../../modal/ImproveModal";

const FaceRank = ({ loading, error, errorText, resultData }) => {
  const [openModalName, setOpenModalName] = useState(null);

  const spinner = loading ? <Spinner /> : null;
  const errorMessage = error ? (
    <ErrorMessage
      message={errorText || "Не удалось загрузить индекс состояния кожи лица"}
    />
  ) : null;

  const content = resultData ? (
    <>
      <h3>Индекс состояния кожи лица</h3>
      <div className="result__circle">
        <CircularProgressbar
          value={resultData.face_rank}
          maxValue={10}
          text={`${resultData.face_rank}`}
          strokeWidth={12}
          styles={buildStyles({
            textSize: "32px",
            pathColor: `rgba(21, 31, 64, ${resultData.face_rank / 10})`,
            textColor: "#000000",
            trailColor: "#d6d6d6",
            backgroundColor: "#EBF5FF",
          })}
        />
      </div>
      <button
        className="result__improve"
        type="button"
        onClick={() => openModal(setOpenModalName, "improve")}
      >
        Как улучшить?
      </button>
      {openModalName === "improve" && (
        <ImproveModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Как улучшить показатели?"
        />
      )}
    </>
  ) : null;

  return (
    <>
      {errorMessage}
      {spinner}
      {content}
    </>
  );
};

export default FaceRank;
