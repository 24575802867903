import DocsPage from "./DocsPage";

const PoliticsPage = () => {
  return (
    <DocsPage title="Privacy policy" description="Политика конфиденциальности">
      <ol>
        <li>
          <h2>Общие положения</h2>
          <p>
            Настоящая политика конфиденциальности (далее - Политика) разработана
            в соответствии с Федеральным законом от 27.07.2006 №152-ФЗ «О
            персональных данных» (далее - ФЗ-152). Настоящая Политика определяет
            цели и порядок обработки персональных данных и иных данных
            пользователей ООО «Скандерм про» (ОГРН 5157746159687, ИНН
            9715233634, КПП 773101001, адрес: 121205, город Москва, тер Сколково
            инновационного центра, Большой б-р, д. 42 стр. 1, помещ. 263) (далее
            - Оператор) с целью защиты прав и свобод человека и гражданина при
            обработке его персональных Данных, в том числе защиты прав на
            неприкосновенность частной жизни, личную и семейную тайну.
          </p>
          <p>
            Публикация настоящей Политики в соответствии с ч. 2 ст. 18.1 ФЗ-152
            осуществляется Оператором посредством размещения настоящего
            документа на сайте Оператора в информационно-телекоммуникационной
            сети «Интернет» по адресу: https://facescan.pro/. Настоящая Политика
            вступает в силу с момента публикации на сайте Оператора в
            информационно-телекоммуникационной сети «Интернет» по адресу:
            https://facescan.pro/
          </p>
          <p>
            Оператор осуществляет сбор данных, не относящихся к персональным
            данным, с момента доступа к Сайту. Сбор персональных данных
            осуществляется при регистрации Пользователя путем заполнения
            Пользователем номера телефона, а также в дальнейшем при
            редактировании Пользователем ранее предоставленных данных (учетной
            записи (профиля), при дополнении по своей инициативе Персональных
            данных с помощью интерфейса Сайта и при использовании Сайта после
            дачи Согласия на обработку персональных данных
            (https://facescan.pro/) путем проставления галочки в соответствующей
            веб-форме «ознакомлен и согласен с Согласием на обработку
            персональных данных»).
          </p>
          <p>
            Пользователь соглашается с тем, что использование Сайта является
            полным и информированным согласием на обработку данных, не
            являющимся Персональными данными, в соответствии с настоящей
            Политикой.
          </p>
          <p>
            Пользователь соглашается с тем, что проставление галочки в
            соответствующей веб-форме «Ознакомлен и согласен на обработку своих
            персональных данных в соответствии с Согласием на обработку
            персональных данных» является полным и информированным согласием
            Пользователя на предоставление Оператору своих Персональных данных,
            а равно Персональных данных третьих лиц, сообщаемых Пользователем в
            соответствии с Согласием на обработку персональных данных.
          </p>
          <p>
            Пользователь соглашается с тем, что проставление галочки в
            соответствующей веб-форме «Ознакомлен и Согласен с Политикой
            конфиденциальности» означает является полным и информированным
            согласием на обработку Персональных данных в соответствии с
            настоящей Политикой.
          </p>
        </li>
        <li>
          <h2>Термины, используемые в Политике, и их определения</h2>
          <p>
            2.1. Персональные данные — любая информация, относящаяся прямо или
            косвенно к определенному или определяемому физическому лицу
            Пользователю Сайта;
          </p>
          <p>
            2.2. Пользователь — физическое лицо, пользователь сети «Интернет»,
            получивший доступ к Сайту и (или) использующий его;
          </p>
          <p>
            2.3. Данные - сведения независимо от формы их представления,
            относящиеся к Пользователю, включая Персональные данные;
          </p>
          <p>
            2.4. Сайт — совокупность программно-аппаратных средств для
            ЭВМ/мобильных устройств, обеспечивающих публикацию данных в Интернет
            для всеобщего обозрения. Сайт доступен по уникальному электронному
            адресу или его буквенному обозначению в сети Интернет по адресу:
            https://facescan.pro/. Может содержать графическую, текстовую,
            аудио-, видео-, а также иную информацию, воспроизводимую с помощью
            ЭВМ/мобильных устройств;
          </p>
        </li>
        <li>
          <h2>Данные, обработка которых осуществляется Оператором</h2>
          <p>
            3.1. Объем и характер Данных, обрабатываемых Оператором в процессе
            работы Сайта, могут различаться в случаях использования
            Пользователем возможности регистрации и создания учетной записи
            (профиля) на Сайте и в случаях получения доступа к Сайту без
            регистрации и создания учетной записи (профиля).
          </p>
          <p>
            3.2. Оператор осуществляет обработку следующих данных, не
            относящихся к Персональным данным, в случаях получения доступа
            Пользователя к Сайту без регистрации и создания учетной записи
            (профиля) на Сайта:
          </p>
          <p>3.2.1. информация о файлах cookies;</p>
          <p>
            3.2.2. информация об активности Пользователя во время его
            использования;
          </p>
          <p>
            3.3. Оператор осуществляет обработку следующих Персональных данных в
            случаях получения доступа к Сайту Пользователем после регистрации и
            создания учетной записи (профиля) на Сайте:
          </p>
          <ul>
            <li>Номер телефона.</li>
            <li>E-mail.</li>
            <li>
              {" "}
              Оператор не собирает и не обрабатывает Биометрические Персональные
              данные, а также специальные Персональные данные, касающиеся
              расовой, национальной принадлежности, политических взглядов,
              религиозных или философских убеждений, состояния здоровья,
              интимной жизни.
            </li>
          </ul>
        </li>
        <li>
          <h2>Цели обработки</h2>
          <p>4.1. Оператор осуществляет обработку Данных в следующих целях:</p>
          <p>
            4.1.1. в целях исполнения договора по оказанию Оператором
            Пользователю услуг по использованию Сайта;
          </p>
          <p>
            4.1.2. в целях осуществления аналитических и статистических
            исследований;
          </p>
          <p>4.1.3. в целях осуществления информационной рассылки;</p>
          <p>
            4.1.4. в целях персонализации предложений, доступных на Сайте, для
            Пользователя;
          </p>
          <p>
            4.1.5. в целях обеспечения защиты прав и законных интересов
            Оператора при нарушении Пользователем Пользовательского соглашения,
            настоящей Политики, либо в ситуации, когда существует угроза такого
            нарушения.
          </p>
        </li>
        <li>
          <h2>Порядок обработки</h2>
          <p>
            5.1. Оператор вправе совершаться любые действия (операции) с
            Данными, допускаемые законодательством Российской Федерации, включая
            сбор, запись, систематизацию, накопление, хранение, уточнение
            (обновление, изменение), извлечение, использование, обезличивание,
            блокирование, удаление, уничтожение, передача в целях, определенных
            в разделе 4 настоящей Политики.
          </p>
          <p>
            Оператор осуществляет сбор cookies. Сookies представляют собой
            небольшие фрагменты данных, которые веб-сайты запрашивают у
            браузера, используемого на компьютере или мобильном устройстве
            Пользователя. Сookies хранятся локально на компьютере или мобильном
            устройстве Пользователя. Сookies содержат информацию, которая
            позволяет определить предпочтения Пользователя, сведения об
            используемом оборудовании, дате и времени сессии и т.п. Оператор
            может обрабатывать полученные сookies с использованием сервисов
            веб-аналитики Рейтинг Mail.ru, Яндекс.Метрика и Google Analytics.
          </p>
          <h3>Передача данных осуществляется в следующем порядке:</h3>
          <p>
            5.3.1. Данные Пользователей подлежат передаче третьим лицам в
            случаях, предусмотренных настоящей Политикой, а также
            законодательством российской федерации.
          </p>
          <p>
            5.3.2. Оператор вправе передать Данные следующим лицам в целях,
            изложенных в разделе 4 настоящей Политики:
          </p>
          <ul>
            <li>ООО "МАЙГРИН МАРКЕТ"</li>
          </ul>
          <p>
            5.3.3. Оператор вправе передавать Данные также иным третьим лицам,
            которые привлекаются для обеспечения функционирования и доработки
            Сайта в целях, изложенных в разделе 4 настоящей Политики. Указанные
            третьи лица указываются Оператором в Согласии на обработку
            персональных данных.
          </p>
          <p>
            5.3.4. Оператор не осуществляет трансграничную передачу Персональных
            данных.
          </p>
          <h3>Хранение осуществляется в следующем порядке:</h3>
          <p>
            Данные хранятся на территории Российской Федерации, при этом
            хранение осуществляется исключительно на электронных носителях, а
            обработка осуществляется с использованием автоматизированных систем,
            за исключением случаев, когда иной способ обработки Данных необходим
            в связи с исполнением договорных обязательств Оператора перед
            Пользователем, а также в случаях, прямо связанных с исполнением
            законодательства, включая случаи предоставления Данных
            государственным (муниципальным) органам власти по запросу последних.
          </p>
          <p>
            Данные, определенные в п. 3.2 настоящей Политики, хранятся до
            достижения целей их обработки. Персональные данные хранятся в
            течение срока действия согласия Пользователя на обработку
            Персональных данных, определенного в Согласии на обработку
            персональных данных.
          </p>
          <p>
            Не допускается извлечение и использование Данных, в том числе в
            коммерческих целях.
          </p>
          <p>
            Оператором вправе обрабатывать Данные с использованием средств
            вычислительной техники (далее – Автоматизированная обработка).
            Автоматизированная обработка может производиться Оператором без
            непосредственного доступа к ним сотрудников и (или) контрагентов
            Оператора, за исключением случаев, когда такой доступ необходим для
            выполнения указанными лицами своих служебных обязанностей или
            обязательств по договору с Оператором, при этом на таких лиц
            возлагается обязанность по соблюдению установленных Оператором
            требований безопасности при осуществлении доступа к Данных. Для
            защиты и обеспечения конфиденциальности Данных все сотрудники и
            контрагенты Оператора соблюдают внутренние правила и процедуры в
            отношении обработки информации. Указанные лица также соблюдают все
            технические и организационные меры безопасности, предусмотренные
            законодательством и необходимые для защиты Данных.
          </p>
          <p>
            При достижении целей обработки Данных Оператор прекращает обработку
            Данных в порядке, предусмотренном законодательством Российской
            Федерации.
          </p>
          <h3>Обработка Данных несовершеннолетних</h3>
          <p>
            Любой Пользователь, принимающий Политику, заверяет Оператора о
            следующих обстоятельствах:
          </p>
          <ul>
            <li>
              - он является полностью дееспособным лицом, согласно
              законодательству Российской Федерации;
            </li>
            <li>
              - если Пользователь является недееспособным (ограниченно
              дееспособным) лицом, то он располагает согласием своего законного
              представителя на обработку Персональных данных Пользователя
              согласно условиям настоящей Политики;
            </li>
          </ul>
          <p>
            В отношении лиц, не достигших возраста 18 лет, а также в отношении
            иных недееспособных (ограниченно дееспособных) лиц согласие на
            обработку Персональных данных указанных лиц дает законный
            представитель.
          </p>
          <p>
            Оставляя Данные на Сайте, Пользователь — законный представитель
            недееспособного (ограниченно дееспособного) лица свободно, своей
            волей и в интересе представляемого соглашается в соответствии с
            Согласием на обработку персональных данных (https://facescan.pro/),
            в том числе, от имени представляемого им лица, на обработку
            Персональных данных представляемого лица.
          </p>
        </li>
        <li>
          <h2>Конфиденциальность</h2>
          <p>
            6.1. Оператор и иные лица, получившие доступ к Данным, обязаны не
            раскрывать третьим лицам и не обрабатывать Данные без согласия
            Пользователя, если иное не предусмотрено настоящей Политикой и
            федеральным законом.
          </p>
        </li>
        <li>
          <h2>Права Пользователя</h2>
          <p>7.1. Пользователь вправе:</p>
          <p>
            7.1.1. требовать от Оператора уточнения его Данных, а также Данных
            представляемого им лица, их блокирования, удаления или уничтожения в
            случае, если Данные являются неполными, устаревшими, неточными,
            незаконно полученными или не являются необходимыми для заявленной
            цели обработки, а также принимать предусмотренные законом меры по
            защите своих прав;
          </p>
          <p>
            7.1.2. получать бесплатный доступ к Данным о себе и представляемых
            им лицах посредством просмотра учетной записи (профиля) на Сайте;
          </p>
          <p>
            7.1.3. Удалять Данные о себе и представляемых лицах путем
            редактирования информации в учетной записи (профиле), при этом
            удаление Пользователем Персональных данных и (или) всей учетной
            записи (профиля) может привести к невозможности предоставления
            Пользователю доступа к Сайту;
          </p>
          <p>
            7.1.4. обжаловать действия или бездействие Оператора в
            уполномоченный орган или в судебном порядке;
          </p>
          <p>
            7.1.5. защищать свои права и законные интересы, а также права и
            интересы представляемых им лиц в области защиты Персональных и иных
            данных, в том числе на возмещение убытков и (или) компенсацию
            морального вреда в судебном порядке;
          </p>
          <p>
            7.1.6. отозвать согласие на обработку своих Персональных данных
            путем направления Оператору письменного заявления Оператору по
            адресу электронной почты info@scanderm.pro с адреса электронной
            почты Пользователя, указанного им в учетной записи Пользователя на
            Сайте.
          </p>
        </li>
        <li>
          <h2>Обеспечение безопасности</h2>
          <p>
            Безопасность Данных, обрабатываемых Оператором, обеспечивается
            реализацией правовых, организационных и технических мер, необходимых
            для обеспечения требований федерального законодательства в области
            защиты Данных.
          </p>
        </li>
        <li>
          <h2>Ограничение ответственности Оператора</h2>
          <p>
            Оператор не несет ответственности за разглашение и распространение
            Персональных данных Пользователя (представляемых им лиц) другими
            Пользователями или иными лицами в случае, если такие лица получили
            доступ к Персональным данным Пользователя или представляемых им лиц
            в результате нарушения Пользователем сохранности его логина и/или
            пароля к учетной записи (профилю) на Сайте, а равно иных необходимых
            для авторизации данных.
          </p>
          <p>
            Оператор не несет ответственности за разглашение и распространение
            Персональных данных Пользователя (представляемых им лиц), за
            исключением случаев, когда такое разглашение (распространение)
            произошло по вине Оператора в результате нарушения им правил,
            предусмотренных настоящей Политикой и (или) законодательством.
          </p>
        </li>
        <li>
          <h2>Обращения Пользователей</h2>
          <p>
            Сведения об обрабатываемых Оператором Персональных данных
            Пользователя и (или) представляемого им лица, предоставляются
            Пользователю или его представителю по соответствующему запросу.
          </p>
          <p>
            Если иное прямо не предусмотрено настоящей Политикой, запросы
            направляются в письменной форме по адресу электронной почты
            info@scanderm.pro Оператора с адреса электронной почты Пользователя,
            указанного им при регистрации на Сайте.
          </p>
        </li>
        <li>
          <h2>Изменения Политики</h2>
          <p>
            Оператор вправе вносить изменения в настоящую Политику в
            одностороннем порядке по своему усмотрению, в том числе, но не
            ограничиваясь, в случаях, когда соответствующие изменения связаны с
            изменениями законодательства, а также в случаях изменения работы
            Сайта.
          </p>
          <p>
            Изменение Политики осуществляется Оператором посредством размещения
            новой редакции Политики на Сайте. Изменения в Политике, внесенные
            Оператором, вступают в силу с момента размещения на Сайте новой
            редакции такой Политики. Пользователь Сайта обязуется самостоятельно
            проверять настоящую Политику на предмет внесенных изменений.
            Неосуществление Пользователем Сайта действий по ознакомлению с
            указанными изменениями не может служить основанием для предъявления
            Пользователем каких-либо требований и (или) претензий к Оператору.
          </p>
          <p>
            Пользователь вправе отказаться от принятия изменений и дополнений в
            настоящую Политику, воспользовавшись правом, предусмотренным п.
            7.1.6. настоящей Политики.
          </p>
        </li>
        <li>
          <h2>Заключительные положения</h2>
          <p>
            Иные права и обязанности Оператора определяются законодательством
            Российской Федерации
          </p>
        </li>
      </ol>
    </DocsPage>
  );
};

export default PoliticsPage;
