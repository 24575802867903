import DocsPage from "./DocsPage";

const RulesPage = () => {
  return (
    <DocsPage title="Rules of using" description="Правила использования">
      <ol>
        <li>
          <h2>Принятие условий</h2>
          <p>
            Переход на данный сайт и его использование осуществляется в
            соответствии со следующими условиями и всеми применимыми законами.
            При входе и просмотре данного сайта вы принимаете, без ограничений
            или оговорок, данные условия, а также признаете, что любые другие
            соглашения относительно использования данного сайта между Вами, ООО
            «Скандерм про» (далее – «СКАНДЕРМ») и ООО «МАЙГРИН МАРКЕТ»,
            отменяются и прекращают действие.
          </p>
          <p>
            Скандерм будет принимать все возможные усилия для обновления
            информации на настоящем сайте, но некоторая информация может быть не
            актуальная с течением времени. В иных, специально не указанных
            случаях, СКАНДЕРМ и ООО «МАЙГРИН МАРКЕТ» специально не заявляет
            требований и не делает заявлений, о точности, полноте и
            использовании информации содержащейся на настоящем сайте.
          </p>
        </li>
        <li>
          <h2>Информация/условия</h2>
          <p>
            Информация о продукте, размещенная на данном веб-сайте,
            предоставлена компанией СКАНДЕРМ и ООО «МАЙГРИН МАРКЕТ» и содержит
            только общие сведения. Ничто на данном веб-сайте не должно быть
            истолковано как предоставление медицинской консультации или принятие
            рекомендаций. Информация, содержащаяся на веб-сайте, не должна быть
            использована в качестве основы для принятия решений или действий.
          </p>
        </li>
        <li>
          <h2>Использование информации</h2>
          <p>
            Вы можете просматривать данный веб-сайт без ограничений, однако
            просматривать, загружать или использовать информацию с данного
            веб-сайта, включая текст, изображения, аудио- и видеофайлы
            («Информация») можно только для личного некоммерческого
            использования. Запрещается распространять, изменять, передавать,
            повторно использовать, публиковать или использовать информацию для
            коммерческих целей без письменного разрешения компании СКАНДЕРМ и
            ООО «МАЙГРИН МАРКЕТ». Необходимо сохранять и воспроизводить все
            уведомления об авторских правах или другую информацию о полномочиях
            собственника, которые содержатся в загружаемой информации. На всю
            текстовую или графическую информацию, размещенную на данном
            веб-сайте, распространяются авторские права, если отсутствуют другие
            уведомления. Такая информация может использоваться только в
            соответствии с данными условиями или условиями, опубликованными на
            веб-сайте без письменного разрешения компании СКАНДЕРМ И ООО
            «МАЙГРИН МАРКЕТ». Компания СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» не
            гарантирует и не заявляет, что использование материалов,
            опубликованных на данном веб-сайте, не нарушит прав третьих сторон,
            которые не принадлежат компании СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» и не
            связаны с ней, если в данном параграфе не предусмотрено иное. За
            исключением упомянутых выше ограниченных полномочий компания
            СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» не предоставляет и не передает
            лицензии или права на информацию или любые авторские права компании
            СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» или третьих сторон.
          </p>
        </li>
        <li>
          <h2>Торговые марки/права собственности (Интеллектуальные права)</h2>
          <p>
            Все названия продуктов, упомянутые на данном веб-сайте, крупным
            шрифтом, курсивом или с символом торговой марки, являются торговыми
            марками компании СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ». Данный сайт также
            может содержать патенты или ссылки на них, информацию о правах
            собственности, технологиях, продуктах, процессах или других
            авторских правах компании СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» и/или
            третьих сторон. Данный сайт также может содержать патенты или ссылки
            на них, информацию о правах собственности, технологиях, продуктах,
            процессах или других авторских правах компании СКАНДЕРМ И ООО
            «МАЙГРИН МАРКЕТ» и/или третьих сторон. Все названия продуктов,
            опубликованные курсивом на данном веб-сайте, являются торговыми
            марками, принадлежащими СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» или
            используемыми по лицензиям. Компания СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ»
            не претендует на какие-либо права собственности, а также на
            принадлежность к любым сторонним товарным знакам, появляющиеся на
            данном веб-сайте. Товарные знаки третьих лиц используются только для
            идентификации продукции и услуг их владельцев и со стороны компании
            СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» не должно быть замечено ни
            спонсорства или поддержки, которую можно было бы извлечь от
            использования этих товарных знаков.
          </p>
        </li>
        <li>
          <h2>Не предоставление гарантий</h2>
          <p>
            Данный веб-сайт был разработан в качестве сервиса компании СКАНДЕРМ
            И ООО «МАЙГРИН МАРКЕТ». Компания СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ»
            прилагает необходимые усилия для обеспечения точности и актуальности
            информации, однако она может содержать неточности или
            типографические ошибки или устареть через некоторое время. Компания
            СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» оставляет за собой права на внесение
            изменений, корректировок и/или усовершенствований в информацию, а
            также в продукты и программы, описанные в данной информации в любое
            время без уведомлений. Компания СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» не
            предоставляет гарантии или заверений относительно точности любой
            части Информации. Компания не несет ответственности за ошибки или
            упущения в содержимом данного веб-сайта. Вся информация
            предоставляется как есть. СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» не
            предоставляет гарантий относительно полноты или точности информации,
            опубликованной на данном веб-сайте, или ее возможного использования.
            Таким образом, посетители веб-сайта должны внимательно оценить
            информацию. СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» или другие
            аффилированные лица группы компаний ООО «МАЙГРИН МАРКЕТ», а также
            другие стороны, вовлеченные в разработку, создание или реализацию
            данного веб-сайта не несут ответственности за прямые, случайные,
            проистекающие, непрямые или штрафные убытки, связанные с доступом,
            использованием или невозможностью использования данного сайта, а
            также с ошибками или упущениями в содержании данного веб-сайта. В
            некоторых регионах исключения подразумеваемой гарантии не разрешены,
            поэтому в вашем случае вышеуказанное исключение может не
            применяться. Также СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» не несет
            ответственности за повреждения или вирусы, которые могут возникнуть
            на вашем компьютерном оборудовании или другой собственности
            вследствие обращения или использования данной информации. СКАНДЕРМ И
            ООО «МАЙГРИН МАРКЕТ» оставляет за собой право на удаление данного
            веб-сайта в любое время без уведомления и обязательств.
          </p>
        </li>
        <li>
          <h2>Информация, которую вы предоставляете нам</h2>
          <p>
            За исключением информации, предусмотренной в нашей политике
            конфиденциальности, сведения или материалы, которые вы передаете на
            веб-сайт по электронной почте или другим образом, включая данные,
            вопросы, комментарии, предложения не будут расцениваться, как
            конфиденциальная и персональная информация. Все передаваемые или
            публикуемые данные станут собственностью СКАНДЕРМ И ООО «МАЙГРИН
            МАРКЕТ» и смежных компаний, и могут использоваться в любых целях,
            включая, среди прочего, предоставление, раскрытие, передачу,
            публикацию, вещание и перенос. Более того, СКАНДЕРМ И ООО «МАЙГРИН
            МАРКЕТ» может свободно использовать без компенсаций любые идеи,
            концепции, ноу-хау или технологии, содержащиеся в информации,
            передаваемой вами на данный веб-сайт, независимо от причин такого
            предоставления, включая, среди прочего, разработку, производство и
            маркетинг продуктов с использованием такой информации, при этом, при
            копировании, загрузке, распечатке или ином использовании информации,
            сведении и материалов, необходимо указать на сайт как на источник
            информации, сведений и материалов, и указать следующую оговорку:
            «Решение подготовлено компаниями ООО "Скандерм Про" и ООО «МАЙГРИН
            МАРКЕТ» © Все права защищены».
          </p>
        </li>
        <li>
          <h2>Продукты на мировом рынке</h2>
          <p>
            Данный веб-сайт может содержать информацию о представленных на
            мировом рынке продуктах и услугах, не все из которых могут быть
            доступными в данной стране. Ссылка на продукт или услугу на данном
            веб-сайте не означает, что такой продукт или услуга доступны или
            будут доступны в вашем регионе. Продукты, упомянутые на данном
            веб-сайте, могут подчиняться различным нормативным требованиям, в
            зависимости от страны применения. Таким образом, посетители могут
            быть уведомлены о том, что определенные разделы данного веб-сайта
            предназначены только для определенных специалистов или жителей
            определенных стран. Информация, содержащаяся на данном веб-сайте, не
            должна расцениваться как реклама продуктов или способов их
            использования, если это противоречит законам и нормам вашей страны
            проживания.
          </p>
        </li>
        <li>
          <h2>Правовая оговорка</h2>
          <p>
            Информация, содержащаяся на данном веб-сайте, не должна
            расцениваться как приглашение или предложение инвестировать
            средства, или купля/продажа ценных бумаг или американских
            депозитарных расписок СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ». В частности,
            фактические результаты и разработки могут значительно отличаться от
            прогнозов, мнений или ожиданий, опубликованных на данном веб-сайте,
            и определенная в прошлом стоимость ценных бумаг не должна
            использоваться, как основа для определения будущей стоимости.
          </p>
        </li>
        <li>
          <h2>Ссылки на данный веб-сайт</h2>
          <p>
            СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» не проверяет веб-сайты третьих
            сторон, которые содержат ссылки на данный веб-сайт, и не несет
            ответственности за содержание страниц других веб-сайтов, связанных
            ссылками с данным веб-сайтом. Если вы хотите связать данный веб-сайт
            со своим ссылкой, разместите ссылку только на домашнюю страницу.
            Запрещается размещать ссылку на другие страницы данного веб-сайта
            без предварительного письменного согласия СКАНДЕРМ И ООО «МАЙГРИН
            МАРКЕТ». Запрещается цитирование или использование части/частей
            данного веб-сайта на веб-сайтах третьих сторон без письменного
            согласия.
          </p>
        </li>
        <li>
          <h2>Ссылки на другие веб-сайты</h2>
          <p>
            Ссылки на веб-сайты третьих сторон могут быть предоставлены в
            интересах и для удобства посетителей данного веб-сайта. Мы приложим
            все усилия для того, чтобы при выходе из этого веб-сайта
            информировать пользователя о том, что условия использования и
            политика конфиденциальности веб-сайтов третьих сторон могут
            отличаться. Однако СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» не несет
            ответственности за ссылки с нашего веб-сайта на другие веб-сайты и,
            в частности, мы не отвечаем за точность или правомерность их
            содержимого. Мы не несем ответственности, проистекающей вследствие
            нарушений или упущений политик конфиденциальности третьих сторон.
            СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» не несет никакой ответственности за
            прямые или косвенные убытки от использования любых сайтов третьих
            лиц.
          </p>
        </li>
        <li>
          <h2>Публикация информации на данном веб-сайте</h2>
          <p>
            Время от времени СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» может отслеживать
            или проверять обсуждения, чаты, публикации, переданные файлы,
            информационные доски на веб-сайте, однако компания СКАНДЕРМ И ООО
            «МАЙГРИН МАРКЕТ» не несет ответственности, проистекающей из
            содержимого этих разделов, а также за ошибки, клевету, оскорбления,
            обвинения, упущения, обман, рекламу, непристойности, порнографию,
            сквернословие, угрозы, раскрытие конфиденциальной информации или
            неточности, содержащиеся в информации, опубликованной в упомянутых
            разделах веб-сайта. Запрещается публиковать или передавать
            противозаконные, рекламные, угрожающие, клеветнические, порочащие,
            непристойные, скандальные, провокационные, порнографические или
            оскорбительные материалы, а также материалы, которые могут
            составлять или провоцировать уголовные преступления, стать причиной
            привлечения к гражданской ответственности или других нарушений
            закона. СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» окажет полную поддержку
            любым правоохранительным органам, а также выполнит постановления
            суда, относительно запроса или приказа о раскрытии личности лиц,
            публикующих упомянутые материалы и информацию.
          </p>
        </li>
        <li>
          <h2>Последствия</h2>
          <p>
            Если нам станет известно, что пользователь нарушил любые из условий,
            содержащихся в данном юридическом заявлении, мы можем немедленно
            предпринять корректирующие действия, включая запрет на использование
            услуг СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ», а также удаление любой
            информации, данных и содержимого, размещенного на данном сайте
            пользователем в любой момент и без уведомления. Если вследствие
            такого нарушения мы понесем потери, то на наше собственное
            усмотрение мы можем потребовать возмещения такого ущерба.
          </p>
        </li>
        <li>
          <h2>Конфиденциальность</h2>
          <p>
            СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» уважает конфиденциальность
            посетителей сайта. Пожалуйста, ознакомьтесь с Политикой о
            Конфиденциальности для информации о Ваших правах и разъяснения
            обязательств, касательно информации раскрываемой на сайте.
          </p>
        </li>
        <li>
          <h2>Проверки</h2>
          <p>
            СКАНДЕРМ И ООО «МАЙГРИН МАРКЕТ» может в любое время проверить данные
            условия и опубликовать изменения к ним. Такие обновления имеют
            обязательную силу, поэтому время от времени рекомендуется посещать
            эту страницу для ознакомления с актуальными условиями.
          </p>
          <p>
            На доступ к данному сайту, а также использование его содержимого
            распространяется действие положений «Правила использования».
          </p>
        </li>
      </ol>
    </DocsPage>
  );
};

export default RulesPage