import React from "react";
import { AuthProvider } from "./authProvider";
import { LanguageRegionProvider } from "./LanguageRegionContext";
import { ProgressProvider } from "./ProgressContext";

const CombinedProvider = ({ children }) => {
  return (
    <ProgressProvider>
      <AuthProvider>
        <LanguageRegionProvider>{children}</LanguageRegionProvider>
      </AuthProvider>
    </ProgressProvider>
  );
};

export default CombinedProvider;
