import { useState } from "react";
import allImages from "../../utils/images";
import { getUrl, openWindow } from "../../utils/shareUtils";


const ShareButtons = () => {
  const [copied, setCopied] = useState(false);

  const handleShareClick = (social) => {
    const url = window.location.origin + window.location.pathname;
    const shareUrl = getUrl(social, url);
    if (shareUrl) {
      openWindow(shareUrl);
    }
  };

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    }).catch((err) => {
      console.error("Не удалось скопировать ссылку: ", err);
    });
  };

  return (
    <ul className="share__list">
      <li>
        <button type="button" onClick={() => handleShareClick("vk")}>
          <allImages.ShareVk />
        </button>
      </li>
      <li>
        <button type="button" onClick={() => handleShareClick("ok")}>
          <allImages.ShareOk />
        </button>
      </li>
      <li>
        <button type="button" onClick={() => handleShareClick("facebook")}>
          <allImages.ShareFb />
        </button>
      </li>
      <li>
        <button type="button" onClick={() => handleShareClick("telegram")}>
          <allImages.ShareTg />
        </button>
      </li>
      <li>
        <button type="button" onClick={() => handleShareClick("twitter")}>
          <allImages.ShareTwit />
        </button>
      </li>
      <li>
        <button type="button" onClick={handleCopyLink}>
          {copied ? <allImages.InstBird /> : <allImages.ShareCopy />}
        </button>
      </li>
    </ul>
  );
};

export default ShareButtons;