import { lazy, Suspense } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Spinner from "../spinner/spinner";
import Modal from "../modal/modal";
import MobMenu from "../mobMenu/mobMenu";
import Footer from "../content/footer/footer";
import Header from "../content/header/header";
import ImprovePage from "../pages/ImprovePage";
import RulesPage from "../pages/RulesPage";
import PoliticsPage from "../pages/PoliticsPage";
import PhotoPage from "../pages/PhotoPage";
import TestPage from "../pages/TestPage";
import ResultPage from "../pages/ResultPage";
import RecsPage from "../pages/RecsPage";

const MainPage = lazy(() => import("../pages/MainPage"));

const App = () => {
  return (
    <Router basename="/">
      <div className="App">
        <div className="wrapper">
          <h1 className="visually-hidden">FaceScan by Greenway</h1>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header />
                    <MainPage />
                    <Footer />
                    <Modal />
                  </>
                }
              />
              <Route path="/improve" element={<ImprovePage />} />
              <Route path="/rules" element={<RulesPage />} />
              <Route path="/privacy" element={<PoliticsPage />} />
              <Route path="/photo" element={<PhotoPage />} />
              <Route path="/test" element={<TestPage />} />
              <Route path="/result" element={<ResultPage />} />
              <Route path="/recs" element={<RecsPage />} />
            </Routes>
          </Suspense>
          <MobMenu />
        </div>
      </div>
    </Router>
  );
};

export default App;
