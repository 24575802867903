import { NavLink } from "react-router-dom";
import allImages from "../../../utils/images";
import { Link } from "react-scroll";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import Modal, { closeModal, openModal } from "../../modal/modal";
import ShareButtons from "../../shareBtns/shareBtns";

const Footer = () => {
  const [openModalName, setOpenModalName] = useState(null);
  const footerRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      footerRef.current,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
        scrollTrigger: {
          trigger: footerRef.current,
          start: "top bottom",
          toggleActions: "play reverse play reverse",
        },
      }
    );
  }, []);

  return (
    <>
      <footer className="footer" ref={footerRef}>
        <div className="container">
          <div className="footer__container">
            <div className="footer__top">
              <NavLink className="footer__logo" to="/">
                <picture>
                  <source
                    srcSet={`${allImages.logoWebp} 1x, ${allImages.logoRetinaWebp} 2x`}
                    type="image/webp"
                  />
                  <img
                    src={allImages.logo}
                    srcSet={allImages.logoRetina}
                    width="94"
                    height="72"
                    alt="логотип FaceScan."
                  />
                </picture>
              </NavLink>
              <ul className="footer__links">
                <li>
                  <Link
                    to="service"
                    smooth={true}
                    duration={500}
                    offset={-50}
                    tabIndex="0"
                  >
                    Как работает сервис?
                  </Link>
                </li>
                <li>
                  <NavLink to="/photo">Готовы начать?</NavLink>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => openModal(setOpenModalName, "share")}
                  >
                    Знаете кому может помочь наш сервис?
                  </button>
                </li>
              </ul>
              <div className="footer__dev">
                <NavLink
                  className="footer__dev-website"
                  to="https://scanderm.pro"
                >
                  <h4>Scanderm</h4>
                  <p>Решение подготовлено компанией ООО "Скандерм Про"</p>
                </NavLink>
                <div>
                  <NavLink
                    to="https://vk.com/greenway_global"
                    aria-label="наша страница в ВК."
                  >
                    <allImages.VkIcon />
                  </NavLink>
                  <NavLink
                    to="https://t.me/greenwayglobalofficial"
                    aria-label="наш канал ТГ."
                  >
                    <allImages.TgIcon />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="footer__bottom">
              <span>© FaceScan by GreenWay</span>
              <nav className="footer__nav">
                <ul>
                  <li>
                    <NavLink to="/faq">Вопросы / Ответы</NavLink>
                  </li>
                  <li>
                    <NavLink to="/improve">Помогите нам стать лучше</NavLink>
                  </li>
                  <li>
                    <NavLink to="/rules">Условия использования сервиса</NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy">Политика конфиденциальности</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer>
      
      {openModalName === "share" && (
        <Modal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Знаете, кому может помочь наш сервис?"
        >
          <p className="modal__description">Выберите одну из социальных сетей, чтобы поделиться с друзьями ссылкой на сервис</p>
          <ShareButtons />
        </Modal>
      )}
    </>
  );
};

export default Footer;
