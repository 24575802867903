import React from "react";
import Survey from "../../testSurvey/testSurvey";

const ImproveSurvey = () => {
  const questions = [
    {
      question_id: 1,
      text: "Насколько просто ориентироваться на страницах сервиса?",
      type: "radio",
      answers: [
        { answer_id: 1, text: "Просто" },
        { answer_id: 2, text: "Удовлетворительно" },
        { answer_id: 3, text: "Довольно сложно" },
        { answer_id: 4, text: "Очень сложно" },
      ],
    },
    {
      question_id: 2,
      text: "Насколько просто вам было пройти онлайн-диагностику?",
      type: "checkbox",
      answers: [
        { answer_id: 5, text: "Очень просто" },
        { answer_id: 6, text: "Долго грузились страницы" },
        { answer_id: 7, text: "Зависла онлайн диагностика по фотографии" },
        { answer_id: 8, text: "Некоторые вопросы были мне непонятны" },
      ],
    },
    {
      question_id: 3,
      text: "Считаете ли вы, что результаты онлайн-диагностики полные и информативные?",
      type: "radio",
      answers: [
        {
          answer_id: 9,
          text: "Результаты полные и содержат всю необходимую информацию",
        },
        {
          answer_id: 10,
          text: "Результаты понятны, но содержат не всю информацию",
        },
        { answer_id: 11, text: "Результаты непонятны или поверхностны" },
        { answer_id: 12, text: "Мне не показали результат" },
      ],
    },
    {
      question_id: 4,
      text: "Была ли вам полезна индивидуальная подборка средств для кожи?",
      type: "radio",
      answers: [
        { answer_id: 13, text: "Полезна" },
        { answer_id: 14, text: "Некоторые позиции были полезны" },
        { answer_id: 15, text: "Бесполезна" },
      ],
    },
    {
      question_id: 5,
      text: "Считаете ли вы, что сервис GreenWay полезен?",
      type: "radio",
      answers: [
        { answer_id: 16, text: "Да, сервис полезен" },
        { answer_id: 17, text: "Частично полезен" },
        { answer_id: 18, text: "Нет, бесполезен" },
      ],
    },
    {
      question_id: 6,
      text: "Опишите, что вам понравилось в использовании сервиса?",
      type: "textarea",
    },
    {
      question_id: 7,
      text: "Опишите, что вам не понравилось в использовании сервиса?",
      type: "textarea",
    },
    {
      question_id: 8,
      text: "Что на ваш взгляд стоит улучшить в работе сервиса?",
      type: "textarea",
    },
    {
      question_id: 9,
      text: "Оцените полезность сервиса от 1 до 10",
      type: "slider",
    },
  ];

  const handleSubmit = (answers) => {
    console.log("Мертвяк", answers);
  };

  return (
    <Survey
      questions={questions}
      title={"Помогите нам стать лучше"}
      onSubmit={handleSubmit}
      initialAnswers={{}}
      redirectPath='/'
      successMessage={"Спасибо за ваш ответ! Мы очень ценим вашу помощь."}
    />
  );
};

export default ImproveSurvey;
