import { useAuthContext } from "../components/providers/authProvider";
import { useHttp } from "../hooks/httpHook";

export const useFsService = () => {
  const { accessToken, tempToken, fetchTempToken } = useAuthContext();
  const { loading, error, errorText, request, clearError } = useHttp();
  const _apiBase = "https://api.facescan.pro";

  // tokens
  const getValidToken = async () => {
    if (accessToken) {
      return accessToken;
    }

    if (tempToken) {
      return tempToken;
    }

    const token = await fetchTempToken();
    return token;
  };

  // new test
  const startNewTest = async () => {
    const res = await request(`${_apiBase}/core/start_new_test/`, "POST");
    return res.data.result;
  };

  // photo
  const postPhoto = async (image) => {
    const res = await request(`${_apiBase}/core/test_photo/`, "POST", {
      photo: image,
    });
    return res.data.result;
  };

  // get Test
  const getTestQuestions = async () => {
    const res = await request(`${_apiBase}/core/test/`);
    return res;
  };

  // send Test
  const postTestAnswers = async (answers) => {
    const res = await request(`${_apiBase}/core/test/`, "POST", {
      data: answers,
    });
    return res;
  };

  // get Results
  const getResults = async () => {
    const res = await request(`${_apiBase}/core/test_result_neuro/`);
    return res;
  };

  // get Recommendations
  const getRecs = async () => {
    const res = await request(`${_apiBase}/core/test_result/`);
    return res;
  };

  // getBasket
  const getBasket = async () => {
    const res = await request(`${_apiBase}/shop/basket/`)
    return res
  }

  // putInBasket
  const putInBasket = async(count, code) => {
    const res = await request(`${_apiBase}/shop/basket/`, "PUT", {
      count: count,
      product_code: code
    })
    return res.data.result
  }

  // getOrderLink
  const getOrderLink = async () => {
    const res = await request(`${_apiBase}/shop/basket/link`)
    return res.data
  }

  // send Region
  const postReg = async (selectedOption) => {
    const res = await request(`${_apiBase}/users/region/`, "POST", {
      region: selectedOption,
    });
    return res.data.result;
  };

  // send Language
  const postLang = async (selectedOption) => {
    const res = await request(`${_apiBase}/users/lang/`, "POST", {
      lang: selectedOption,
    });
    return res.data.result;
  };

  return {
    loading,
    error,
    errorText,
    clearError,
    postReg,
    postLang,
    startNewTest,
    postPhoto,
    getValidToken,
    getTestQuestions,
    postTestAnswers,
    getResults,
    getRecs,
    getBasket,
    putInBasket,
    getOrderLink,
  };
};

export default useFsService;
