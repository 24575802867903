import { NavLink } from "react-router-dom";
import allImages from "../../utils/images";
import { useAuthContext } from "../providers/authProvider";
import TestNav from "../testNav/testNav";

const SideDashboard = ({ type }) => {
  const { isLoggedIn } = useAuthContext();

  const navData = {
    profile: [
      {
        to: "/profile-tests",
        className: "dashboard__profile-photo",
        icon: <allImages.DashPhoto />,
      },
      {
        to: "/profile-recs",
        className: "dashboard__profile-recs",
        icon: <allImages.DashPhoto />,
      },
      {
        to: "/profile-data",
        className: "dashboard__profile-data",
        icon: <allImages.DashPhoto />,
      },
    ],
    admin: [
      {
        to: "/profile-tests",
        className: "dashboard__profile-photo",
        icon: <allImages.DashPhoto />,
      },
      {
        to: "/profile-recs",
        className: "dashboard__profile-recs",
        icon: <allImages.DashPhoto />,
      },
      {
        to: "/profile-data",
        className: "dashboard__profile-data",
        icon: <allImages.DashPhoto />,
      },
      {
        to: "/profile-admin",
        className: "dashboard__profile-admin",
        icon: <allImages.DashPhoto />,
      },
    ],
    default: [
      {
        to: "/photo",
        className: "dashboard__begin",
        icon: <allImages.DashPhoto />,
      },
    ],
  };

  const currentNavItems = navData[type] || navData.default;

  return (
    <>
      <NavLink to="/">
        <picture>
          <source srcSet={`${allImages.DashLogoWebp2x}`} type="image/webp" />
          <img
            src={allImages.DashLogoWebp}
            width="54"
            height="40"
            alt="логотип FaceScan."
          />
        </picture>
      </NavLink>
      <nav className="dashboard__nav">
        {type === "test" ? (
          <TestNav hideText={true} />
        ) : (
          <ul>
            {currentNavItems.map((item, index) => (
              <li key={index}>
                <NavLink
                  aria-label={item.className}
                  to={item.to}
                  className={({ isActive }) =>
                    isActive ? `${item.className} active` : item.className
                  }
                >
                  {item.icon}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </nav>
      <ul className="dashboard__bottom">
        <li>
          <NavLink
            aria-label={isLoggedIn ? "Просмотреть профиль" : "Войти"}
            className={({ isActive }) =>
              isActive ? "dashboard__profile active" : "dashboard__profile"
            }
            to={isLoggedIn ? "/profile" : "/login"}
          >
            {isLoggedIn ? <allImages.DashProfile /> : <allImages.DashLogin />}
          </NavLink>
        </li>
        <li>
          <NavLink
            aria-label="На главную"
            className="dashboard__home"
            to="/"
          >
            <allImages.DashHome />
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default SideDashboard;
