import Modal from "./modal";

const ImproveModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={"Как улучшить показатели?"}>
      <p className="modal__description">
        Чтобы улучшить показатели, следует следовать некоторым правилам нашего
        сервиса:
      </p>
      <ol className="result__improve-modal">
        <li>Ознакомиться с рекомендациями нашего сервиса.</li>
        <li>
          Каждый предложенный нами продукт, основанный на рекомендациях
          дерматологов, влияет на определенные показатели. Старайтесь соблюдать
          рекомендации по частоте и способам применения, для улучшения
          показателей.
        </li>
        <li>
          На основе использования средства, контролировать изменения показателей
          и заносите результаты в личный дневник.
        </li>
      </ol>
    </Modal>
  );
};

export default ImproveModal;
