import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./components/app/App";
import CombinedProvider from "./components/providers/CombinedProvider";
import "./styles/styles.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <StrictMode>
    <CombinedProvider>
      <App />
    </CombinedProvider>
  // </StrictMode>
);
