// authContext.js
import React, { createContext, useContext, useEffect } from "react";
import { useState, useCallback } from "react";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const _apiBase = "https://api.facescan.pro";

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [tempToken, setTempToken] = useState(
    localStorage.getItem("tempToken")
  );

  const fetchTempToken = useCallback(async () => {
    try {
      const res = await axios.get(`${_apiBase}/users/temp_user_create/`);
      const token = res.data?.temp_token;

      if (token) {
        setTempToken(token);
        localStorage.setItem("tempToken", token);
        return token;
      }
    } catch (e) {
      console.error("Failed to get temp token", e);
      throw e;
    }
  }, []);

  const login = (token) => {
    setAccessToken(token);
    setTempToken(null);
    localStorage.setItem("accessToken", token);
    localStorage.removeItem("tempToken"); 
  };

  const logout = () => {
    setAccessToken(null);
    setTempToken(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("tempToken");
  };

  useEffect(() => {
    if (!accessToken && !tempToken) {
      fetchTempToken();
    }
  }, [fetchTempToken, accessToken, tempToken]);

  const isLoggedIn = Boolean(accessToken);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        tempToken,
        fetchTempToken,
        setAccessToken,
        setTempToken,
        isLoggedIn,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
