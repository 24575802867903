import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import Spinner from "../../../spinner/spinner";
import ErrorMessage from "../../../error/error";

const CareSection = ({
  careData,
  prevClassName,
  nextClassName,
  cart,
  loadingProducts,
  errorProducts,
  handleAddToCart,
  handleRemoveFromCart,
  openAnalogsModal,
  errorText,
  isLoading,
  isError,
}) => {
  return (
    <>
      {isLoading && <Spinner />}
      {isError && <ErrorMessage message={errorText} />}
      {!isLoading && !isError && (
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: `.${nextClassName}`,
            prevEl: `.${prevClassName}`,
          }}
          pagination={{
            el: `.swiper-pagination-${nextClassName}`,
            clickable: true,
          }}
          spaceBetween={20}
          slidesPerView={"auto"}
          className="recs__slider"
        >
          {careData.map((item, index) => {
            if (item.list_products.length > 0) {
              const product = item.list_products[0];

              const hasAnalogInCart = item.list_products.some(
                (analog) => analog.code !== product.code && cart[analog.code] > 0
              );

              const productCount = cart[product.code] || 0;
              const isLoadingProduct = loadingProducts[product.code];
              const isErrorProduct = errorProducts[product.code];
              const isSelectedAnalog = hasAnalogInCart;

              return (
                <SwiperSlide
                  key={index}
                  className={`recs__card ${isSelectedAnalog ? "active" : ""}`}
                  data-card-id={product.code}
                >
                  {isLoadingProduct && <Spinner />}
                  {isErrorProduct && <ErrorMessage message={errorText} />}
                  {!isLoadingProduct && !isErrorProduct && (
                    <>
                      <span className="recs__stage">Этап {index + 1}</span>
                      <img src={product.img} alt={product.name} />
                      <b>{product.stage_name.name}</b>
                      <p>{product.name}</p>
                      <button
                        className={`btn ${
                          isSelectedAnalog ? "btn--pink" : "btn--transblue"
                        }`}
                        onClick={() => openAnalogsModal(item.list_products)}
                      >
                        Аналоги
                      </button>
                      {productCount > 0 ? (
                        <div className="recs__counter">
                          <button
                            className="btn btn--transblue"
                            onClick={() => handleRemoveFromCart(product.code)}
                          >
                            <span>-</span>
                          </button>
                          <p>{productCount}</p>
                          <button
                            className="btn btn--transblue"
                            onClick={() => handleAddToCart(product.code)}
                          >
                            <span>+</span>
                          </button>
                        </div>
                      ) : (
                        <button
                          className="btn btn--blue"
                          onClick={() => handleAddToCart(product.code)}
                        >
                          В корзину
                        </button>
                      )}
                    </>
                  )}
                </SwiperSlide>
              );
            }
            return null;
          })}
        </Swiper>
      )}
      <div
        className={`swiper-button swiper-button-prev ${prevClassName}`}
      ></div>
      <div
        className={`swiper-button swiper-button-next ${nextClassName}`}
      ></div>
      <div
        className={`swiper-pagination swiper-pagination-${nextClassName}`}
      ></div>
    </>
  );
};

export default CareSection;
