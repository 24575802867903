import { NavLink } from "react-router-dom";
import { useEffect, useRef } from "react";
import allImages from "../../utils/images";
import { useProgress } from "../providers/ProgressContext";

const TestNav = ({ hideText = false }) => {
  const navRef = useRef(null);
  const { progress } = useProgress();

  useEffect(() => {
    if (navRef.current) {
      const activeLink = navRef.current.querySelector(".active");
      if (activeLink) {
        const offsetLeft = activeLink.offsetLeft;
        const parentWidth = navRef.current.clientWidth;
        const linkWidth = activeLink.clientWidth;

        navRef.current.scrollTo({
          left: offsetLeft - parentWidth / 2 + linkWidth / 2,
          behavior: "smooth",
        });
      }
    }
  }, [progress]);

  return (
    <ul className="test__nav" ref={navRef}>
      <li>
        <NavLink
          to="/photo"
          className={({ isActive }) =>
            isActive ? "active" : progress.photoUploaded ? "done" : ""
          }
        >
          <allImages.DashPhoto />
          {!hideText && <span>Анализ</span>}
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/test"
          className={({ isActive }) =>
            progress.photoUploaded
              ? isActive
                ? "active"
                : progress.testCompleted
                ? "done"
                : ""
              : "disabled"
          }
          onClick={(e) => {
            if (!progress.photoUploaded) {
              e.preventDefault();
            }
          }}
        >
          <allImages.DashTest />
          {!hideText && <span>Тест</span>}
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/result"
          className={({ isActive }) =>
            progress.testCompleted
              ? isActive
                ? "active"
                : progress.resultsViewed
                ? "done"
                : ""
              : "disabled"
          }
          onClick={(e) => {
            if (!progress.testCompleted) {
              e.preventDefault();
            }
          }}
        >
          <allImages.DashRes />
          {!hideText && <span>Результаты</span>}
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/recs"
          className={({ isActive }) =>
            progress.resultsViewed ? (isActive ? "active" : "done") : "disabled"
          }
          onClick={(e) => {
            if (!progress.resultsViewed) {
              e.preventDefault();
            }
          }}
        >
          <allImages.DashRecs />
          {!hideText && <span>Рекомендации</span>}
        </NavLink>
      </li>
    </ul>
  );
};

export default TestNav;
