import { useEffect, useState } from "react";
import useFsService from "../../../services/fsService";
import Spinner from "../../spinner/spinner";
import ErrorMessage from "../../error/error";
import Modal, { closeModal, openModal } from "../../modal/modal";
import ShareButtons from "../../shareBtns/shareBtns";
import allImages from "../../../utils/images";
import { NavLink } from "react-router-dom";
import FaceRank from "./faceRank/faceRank";
import FaceConditions from "./faceConds/faceConds";
import ImproveModal from "../../modal/ImproveModal";
import { useProgress } from "../../providers/ProgressContext";

const ResultContent = () => {
  const { getResults, loading, error, errorText } = useFsService();
  const [resultData, setResultData] = useState(null);
  const [openModalName, setOpenModalName] = useState(null);

  const { updateProgress } = useProgress();

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const res = await getResults();
        if (res.data.result) {
          setResultData(res.data.data.test_result);

          setTimeout(() => {
            updateProgress({ resultsViewed: true });
          }, 3000);
        }
      } catch (e) {}
    };

    fetchResults();
  }, []);

  return (
    <>
      <div className="result">
        <div className="container">
          <div className="result__container">
            <h1>Результаты</h1>
            <div className="result__data">
              <section
                className="result__section result__index"
                style={{ gridArea: "topleft" }}
              >
                <FaceRank
                  loading={loading}
                  error={error}
                  errorText={errorText}
                  resultData={resultData}
                />
              </section>
              <section
                className="result__section result__age"
                style={{ gridArea: "topmid" }}
              >
                {renderSkinAge(
                  loading,
                  error,
                  errorText,
                  resultData,
                  setOpenModalName
                )}
              </section>
              <button
                type="button"
                className="result__share btn btn--transblue"
                onClick={() => openModal(setOpenModalName, "share")}
                style={{ gridArea: "midmid" }}
              >
                <allImages.ShareIcon />
                <span>Поделиться результатами</span>
              </button>
              {/* <section
                className="result__section result__remind"
                style={{ gridArea: "topright" }}
              >
                <h3>След. тестирование</h3>
                <span>???</span>
              <button type="button">Поставить напоминание</button>
              </section> */}
              <NavLink
                className="result__next btn btn--blue"
                style={{ gridArea: "midright" }}
                to="/recs"
              >
                Перейти к рекомендациям
              </NavLink>
              <section
                className="result__section result__details"
                style={{ gridArea: "bottom" }}
              >
                <div className="result__conds-top">
                  <h2>Детализация показателей</h2>
                  <div className="result__conds-points">
                    <span>Нет</span>
                    <span>Низ</span>
                    <span>Ср</span>
                    <span>Выс</span>
                  </div>
                </div>
                <FaceConditions
                  loading={loading}
                  error={error}
                  errorText={errorText}
                  resultData={resultData}
                />
              </section>
            </div>
          </div>
        </div>
      </div>
      {openModalName === "share" && (
        <Modal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Знаете, кому может помочь наш сервис?"
        >
          <p className="modal__description">
            Выберите одну из социальных сетей, чтобы поделиться с друзьями
            ссылкой на сервис
          </p>
          <ShareButtons />
        </Modal>
      )}
      {openModalName === "improve" && (
        <ImproveModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Как улучшить показатели?"
        />
      )}
    </>
  );
};

const renderSkinAge = (
  loading,
  error,
  errorText,
  resultData,
  setOpenModalName
) => {
  const getAgeLabel = (age) =>
    age % 10 === 1 && age % 100 !== 11
      ? `${age} год`
      : age % 10 >= 2 && age % 10 <= 4 && (age % 100 < 10 || age % 100 >= 20)
      ? `${age} года`
      : `${age} лет`;

  const spinner = loading ? <Spinner /> : null;
  const errorMessage = error ? (
    <ErrorMessage
      message={errorText || "Не удалось загрузить возраст кожи лица"}
    />
  ) : null;
  const content = resultData ? (
    <>
      <h3>Возраст кожи</h3>
      <span>{getAgeLabel(resultData.age)}</span>
      <button
        className="result__improve"
        type="button"
        onClick={() => setOpenModalName("improve")}
      >
        Как улучшить?
      </button>
    </>
  ) : null;

  return (
    <>
      {errorMessage}
      {spinner}
      {content}
    </>
  );
};

export default ResultContent;
